import React from "react";
import { Link } from "gatsby";

import LogoImage from "../../assets/images/logo.png";

import "./Logo.scss";

export default class Logo extends React.PureComponent {
    render() {
        return (
            <Link to="/">
                <img src={LogoImage} className="logo-image" alt="The Real Taglines of Every Housewife"/>
            </Link>
        );
    }
}