import React from "react"

import "./Ad.scss";

export default () => (
    <div className="ad-holder">
        <div className="ad">
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1091472227280543" crossorigin="anonymous"></script>
            <ins className="adsbygoogle"
                style={{"display":"block"}}
                data-ad-client="ca-pub-1091472227280543"
                data-ad-slot="5338894927"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
            <script dangerouslySetInnerHTML={{__html: '(adsbygoogle = window.adsbygoogle || []).push({});'}}></script>
        </div>
    </div>
);