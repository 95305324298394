import React from "react";
import { Link } from "gatsby";

import "./Footer.scss";

export default class Footer extends React.PureComponent {
    render() {
        return (
            <footer className="site-footer">
                <h3 className="site-footer__site-name">&copy; The Real Taglines of Every Housewife</h3>
                <span className="site-footer__site-credit">
                    All taglines and images <a href="https://www.bravotv.com/">&copy; Bravo TV</a> unless otherwise noted.
                </span>
                <ul className="site-footer__links">
                    <li className="site-footer__link">
                        <a href="#top">^ Top</a>
                    </li>
                    <li className="site-footer__link">
                        <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li className="site-footer__link">
                        <a href="https://www.twitter.com/realtaglines">Tweet us!</a>
                    </li>
                    <li className="site-footer__link">
                        <a href="https://www.instagram.com/realtaglines">Gram us!</a>
                    </li>
                </ul>
            </footer>
        );
    }
}