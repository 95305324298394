import React from "react";
import {Helmet} from "react-helmet";

import Ad from "../ads/Ad";
import Footer from "../footer/Footer";

import "./default.scss";

export default ({ children, header = null, adCount = 1 }) => {
    if (header != null) {
        header = <div className="header-default">{ header }</div>;
    }

    const ads = []
    for(let i=0; i<adCount; i++) {
        ads.push(<Ad></Ad>)
    }

    return (<>
            <Helmet>
                <meta name="viewport" content="width=device-width, user-scalable=no" />
                <link href="https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,400;0,700;1,400;1,700&display=swap" rel="stylesheet" />
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1091472227280543" crossorigin="anonymous"></script>
            </Helmet>
            <span id="top"></span>
            { header }
            <div className="container-default container-default--flex container-default--main">
                <div className="col-left">
                    { children }
                </div>
                <div className="col-right">
                    { ads.map(a => a) }
                </div>
            </div>
            <div className="container-default container-default--footer">
                <Footer />
            </div>
        </>);
}